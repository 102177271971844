import { colors } from '../../utils/theme';
import React from 'react';
import Page from '../../components/page';
import { GQLApp, GQLAppNew, GQLBasicItem, GQLWalk } from 'townapps-models/graphql/types';
import { gql, useQuery } from '@apollo/client';
import Image from 'next/image';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
import useScreenView from '../../hooks/useScreenView';

import client from '../../utils/apolloClient';
import getAmplitude from '../../utils/amplitude';
import useLogView from '../../hooks/useLogView';
import { defaultSEODescription } from '../../utils/seo';
import dynamic from 'next/dynamic';
import { imageDefaultShare } from '../../utils/defaultImageShare';

const NotFound = dynamic(() => import('../../components/404'), { ssr: false });
const Card = dynamic(() => import('../../components/card'), { ssr: false });
const HeroBanner = dynamic(() => import('../../components/hero'), { ssr: true });
const LogosApp = dynamic(() => import('../../components/logosApp'), { ssr: false });
const HikeTourCity = dynamic(() => import('../../components/hiketourcity'), { ssr: false });
const FeaturedBusinessesSection = dynamic(() => import('../../components/featuredBusinessesSection'), { ssr: false });
const ShopLocalSection = dynamic(() => import('../../components/shopLocalSection'), { ssr: false });

const GET_APPS = gql`
  query getData {
    apps: getAvailableApps {
      id
      name
      slug
      blurhash
    }
  }
`;

const GET_LISTINGS = gql`
  query getData($slug: String!) {
    hikes: getRandomHikes(slug: $slug, limit: 4) {
      id
      image
      title
      description
      type
      blurhash
      fbOgImage
      slug
      app {
        slug
      }
    }
  }
`;

const GET_DATA = gql`
  query getData($slug: String, $limit: Int) {
    app: getApp(slug: $slug) {
      id
      name
      icon
      squareLogo
      appDetail
      colour
      sponsoredLogo
      sponsoredLogoLink
      vouchersEnabled
      seoTitle
      seoDescription
      cards {
        attraction {
          title
        }
        shopping {
          title
        }
        tour {
          title
        }
        useful {
          title
        }
        clubs {
          title
        }
        walks {
          title
        }
        food {
          title
        }
        lifeStyle {
          title
        }
        lodging {
          title
        }
        news {
          title
        }
        services {
          title
        }
      }
      heroImage
      firstSponsor
      secondSponsor
      thirdSponsor
      fourthSponsor
      fifthSponsor
      sixthSponsor
      firstSponsorLink
      secondSponsorLink
      thirdSponsorLink
      fourthSponsorLink
      fifthSponsorLink
      sixthSponsorLink
      slug
      blurhash
      users {
        email
      }
      location {
        coordinates
      }
      teamPageArea {
        aboutUsText
        membershipURL
        benefitsButtonText
        benefitsContent
        benefitsDownloadURL
        benefitsHeader
        banner
        gallery
        openingHours
        team {
          name
          title
          image
        }
      }
    }
    news: getAppNews(slug: $slug, limit: $limit) {
      id
      title
      image
      authorImage
      content
      slug
      blurhash
      fbOgImage
      htmlContent
      scheduledTime
      excerpt
      appId
      createdAt
      updatedAt
    }
  }
`;

type IProps = {
  app: GQLApp;
  news: GQLAppNew[];
};

function Home({ app, news }: IProps) {
  const data = useQuery<{ hikes: GQLWalk[] }, { slug: string }>(GET_LISTINGS, {
    variables: { slug: app?.slug },
    skip: !app || !app.slug,
  });
  const hikes = data.data?.hikes ?? [];

  if (!app) return <NotFound />;

  useLogView(undefined, app?.id, 'Homepage');
  useScreenView(`${app.name} homepage`);

  const trackView = async (item: GQLBasicItem, event: string) => {
    const amplitude = await getAmplitude();
    amplitude.logEvent(event, {
      itemType: item.type,
      app: app.name,
    });
  };

  return (
    <Page>
      <NextSeo
        title={app.seoTitle ? app.seoTitle : `Xplore: ${app.name}`}
        description={app.seoDescription ? app.seoDescription : defaultSEODescription}
        canonical={`https://xploreapp.io/${app.slug}`}
        openGraph={{
          images: [
            {
              url: imageDefaultShare,
              alt: 'Xplore',
            },
          ],
          title: app.seoTitle ? app.seoTitle : `Xplore: ${app.name}`,
          type: 'website',
          locale: 'en_GB',
          url: `https://xploreapp.io/${app.slug}`,
          site_name: 'xplore',
          description: app.seoDescription ? app.seoDescription : defaultSEODescription,
        }}
      />
      <HeroBanner
        image={app.heroImage}
        title={`Welcome to ${app.name}`}
        subtitle={`Fáilte ${app.name}`}
        description={app.seoDescription ? app.seoDescription : defaultSEODescription}
      />
      <div
        className="w-full h-37 md:h-56 lg:h-52 relative -top-20 flex flex-row justify-between items-center p-8 -mb-12"
        style={{ backgroundColor: 'rgba(81, 167, 146, 0.44)' }}>
        <LogosApp app={app} />
      </div>

      <div className="xplore-section">
        <div className="flex flex-row justify-between items-baseline w-full mb-8">
          <h3 style={{ color: colors.black }} className="xplore-title">
            Na scéalta is deireanaí – Latest News
          </h3>

          {news && news.length > 0 && (
            <span className="hidden md:flex">
              <Link href={`/${app.slug}/news`}>
                <p
                  onClick={async () => {
                    const amplitude = await getAmplitude();
                    amplitude.logEvent(`Clicked on see all news`, { app: app.name });
                  }}
                  style={{ color: colors.lightGreen }}
                  className="cursor-pointer text-right font-bold text-md mb-0 mt-0">
                  See Tuilleadh – See More
                </p>
              </Link>
            </span>
          )}
        </div>

        <div className="mt-6 w-full">
          {news && news.length > 0 && (
            <div className="flex justify-between overflow-x-auto pb-4">
              {news.map(n => (
                <Card
                  href={`${app.slug}/news/${n.slug}`}
                  style={{ width: 350 }}
                  className="mr-4 lg:mr-9"
                  date={n.scheduledTime || n.createdAt}
                  title={n.title}
                  image={n.image}
                  description={n.excerpt}
                  key={n.id}
                  onClick={() => trackView(n as any, 'Clicked on town homepage new')}
                />
              ))}
            </div>
          )}

          {(!news || news.length === 0) && (
            <h1 style={{ color: colors.black, textAlign: 'center' }} className="text-2xl">
              No recent news
            </h1>
          )}
        </div>

        {news && news.length > 0 && (
          <span className="md:hidden flex">
            <Link href={`/${app.slug}/news`}>
              <p
                onClick={async () => {
                  const amplitude = await getAmplitude();
                  amplitude.logEvent(`Clicked on see all news`, { app: app.name });
                }}
                style={{ color: colors.lightGreen }}
                className="cursor-pointer text-right font-bold text-sm md:text-md mb-0 mt-0">
                See Tuilleadh – See More
              </p>
            </Link>
          </span>
        )}
      </div>

      <ShopLocalSection app={app} />

      <FeaturedBusinessesSection app={app} />

      <div className="w-full pt-16 pb-16 relative h-[640px] mb-24">
        <Image src="/tours.jpeg" priority={false} layout="fill" objectFit="cover" alt={'Tours Image'} />
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="xplore-section mb-0 relative top-1/2">
            <h2 className="xplore-title text-white !mb-4">Turais Féin-treoraithe - Self Guided Tours</h2>
            <h3 className="xplore-subtitle !text-white !mb-12">
              Discover the past with self-guided tours exploring history. Interactive audio and turn-by-turn directions bringing the past to life
            </h3>

            <Link href={`/${app.slug}/tour`} passHref>
              <a className="xplore-btn">Take me back</a>
            </Link>
          </div>
        </div>
      </div>

      {hikes.length > 0 && <div className="xplore-section">{hikes.length > 0 && <HikeTourCity items={hikes} app={app} />}</div>}
    </Page>
  );
}

export async function getStaticPaths() {
  const apolloClient = client;
  const { data } = await apolloClient.query({ query: GET_APPS });

  return {
    paths: data.apps.map(d => ({ params: { slug: d.slug, id: d.id } })),
    fallback: true,
  };
}

export async function getStaticProps({ params }) {
  const { data } = await client.query({
    query: GET_DATA,
    variables: {
      slug: params.slug.toLowerCase(),
      limit: 4,
    },
  });

  return {
    props: { app: data?.app, news: data?.news ?? [] },
    revalidate: 9600,
  };
}

export default Home;
