import { useEffect } from 'react';
import { GQLItemViewInput } from 'townapps-models/graphql/types';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';

const QUERY = gql`
  mutation logView($input: ItemViewInput) {
    logView(input: $input)
  }
`;

const useLogView = (itemID: string, appID: string, itemType: string, isPreview: boolean = false) => {
  const [logView] = useMutation<boolean, { input: GQLItemViewInput }>(QUERY);
  useEffect(() => {
    if (itemType) {
      const view: GQLItemViewInput = {
        itemID,
        appId: appID || undefined,
        itemType,
        deviceType: window?.innerWidth <= 500 ? 'phone' : window?.innerWidth <= 1000 ? 'tablet' : 'desktop',
        from: 'web',
      };

      try {
        if (!isPreview) {
          logView({ variables: { input: view } });
        }
      } catch (e) {
        console.error('Cant register analytics view.');
      }
    }
  }, [itemID, appID]);
};

export default useLogView;
