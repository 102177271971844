import { useEffect } from 'react';
import firebase from '../utils/firebase';

const useScreenView = (screenName: string) => {
  useEffect(() => {
    if (screenName && firebase.client) {
      try {
        firebase.client.analytics().setCurrentScreen(screenName);
      } catch (e) {}
    }
  }, [screenName, firebase.client]);
};

export default useScreenView;
